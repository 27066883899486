import { Fragment, useEffect, useRef } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { useCookies } from '../hooks/useCookies'
import ContainerLogin, { TextDontHaveAnAccount } from '../components/ContainerLogin'
import { Input, InputGroup } from '../components/Input'
import TextRed from '../components/ErrorField'
import { REGEX } from '../utils/regex'
import { CONSTANT } from '../constant'
import showPassword from '../utils/form/showPasswordInput'
import { useMessageBackend } from '../hooks/useMessageBackend'
import { loginService } from '../service/login/loginService';
import { checkCurrentRole } from '../utils/common/checkCurrentRole'

function Login() {
  const { messageBackend } = useMessageBackend()
  const { getCookie } = useCookies()

  if (getCookie('token')) {
    const currentRole = checkCurrentRole(getCookie('token'))
    if (currentRole === "student") {
      return <Redirect to={`/dashboard`} />
    } else if (currentRole === "superadmin") {
      return <Redirect to={`/admin/payment`} />
    }
    return <Redirect to={`/${currentRole}`} />
  }

  return (
    <ContainerLogin title="Hai, Selamat Datang Kembali di Star Bimbel" subTitle="Mau Belajar Apa Hari Ini?">
      {messageBackend.status === "success" && <span className='text-sm mb-2 text-green-600'>Pendaftaran berhasil, silahkan login</span>}
      <FormLogin />
    </ContainerLogin>
  )
}

export function FormLogin({ admin, teacher }) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { email, password } = { email: watch('email'), password: watch('password') }
  const passwordRef = useRef(), checkedRef = useRef()
  const history = useHistory()

  const { setCookie, getCookie, removeCookie } = useCookies()

  const onSubmit = async data => {
    if (admin) {
      return loginService(data, setCookie, history, 'admin')
    } else if (teacher) {
      return loginService(data, setCookie, history, 'teacher')
    }
    
    await loginService(data, setCookie, history, 'auth')
  };

  useEffect(() => {
    if (checkedRef.current.checked) {
      setCookie('login_cookie', { email, password }, '/')
    }
  }, [email, getCookie, password, setCookie])

  useEffect(() => {
    if (getCookie('login_cookie')) {
      return checkedRef.current.checked = true
    }
  }, [getCookie])

  const handleCheckedRememberMe = (event) => {
    if (event.target.checked) {
      return setCookie('login_cookie', {
        email: getCookie('login_cookie').email ? getCookie('login_cookie').email : email,
        password: getCookie('login_cookie').password ? getCookie('login_cookie').password : password,
      }, '/')
    }

    removeCookie('login_cookie', '/')
  }

  const login_cookie = getCookie('login_cookie')

  return (
    <Fragment>
      <h1 className='text-2xl font-semibold'>Login</h1>
      {!admin && <TextDontHaveAnAccount teacher={teacher} />}
      <form className='mt-4' onSubmit={handleSubmit(onSubmit)} >
        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="email">Username</label>
          <Input defaultValue={login_cookie.email ? login_cookie.email : ''} register={register("email", {
            pattern: admin ? null : null,
            maxLength: 100,
            required: true,
          })} id="email" placeholder="Alamat Email" showIcon iconName='email' />
          {errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>}
        </InputGroup>

        <InputGroup>
          <label ref={passwordRef} className='mb-2 text-sm' htmlFor="password">Password</label>
          <Input defaultValue={login_cookie.password ? login_cookie.password : ''} register={register("password", {
            pattern: admin ? null : REGEX.password,
            required: true,
          })} type="password" id="password" placeholder="Masukan Password" showIcon iconName='eye' cursorIcon='cursor-pointer' eventClickIcon={() => showPassword(passwordRef)} />
          {errors.password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>}
        </InputGroup>

        <div className='mt-4 w-full flex justify-between'>
          <div className='flex items-center text-xs'>
            <label className='order-2 ml-2 ' htmlFor="ingat">Ingat Saya</label>
            <input ref={checkedRef} className='order-1 border-gray' type="checkbox" id="ingat" onChange={handleCheckedRememberMe} />
          </div>
          {/* <Link to={`/forgot-password${teacher ? '?me=teacher' : `${admin ? '?me=admin' : ''}`}`}>
            <span className='txt-blue text-xs cursor-pointer'>Lupa Password?</span>
          </Link> */}
        </div>
        <button className='shadow-md w-full p-4 rounded-2xl text-white bg-blue mt-6 md:text-base text-sm'>Login</button>
      </form>
      {/* <AnotherLogin /> */}
    </Fragment>
  )
}

// function AnotherLogin() {
//   return (
//     <div className='flex flex-col mt-6'>
//       <div className='flex items-center txt-gray justify-evenly'>
//         <span className='h-0.5 w-2/6 border-gray'></span>
//         <span className='font-semibold md:text-base text-sm'>OR</span>
//         <span className='h-0.5 w-2/6 border-gray'></span>
//       </div>

//       <div className='flex mt-6 justify-center items-center'>
//         <button className='w-1/2 flex justify-center border-black mr-4 rounded-xl text-center p-3 md:text-sm text-xs items-center'>
//           <img className='w-6 mr-2' src="/assets/icons/google.png" alt="google" />
//               Google
//             </button>
//         <button className='w-1/2 flex justify-center border-black rounded-xl text-center p-3 md:text-sm text-xs items-center'>
//           <img className='w-6 mr-2' src="/assets/icons/fb.png" alt="facebook" />
//             Facebook
//           </button>
//       </div>
//     </div>
//   )
// }

export default Login