/* eslint-disable no-restricted-globals */
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ContentDashboard, MainDashboard } from '../../../layouts/Dashboard';
import { NavDashboard } from '../../../components/Navbar'
import { TabPackage } from '../../../components/Tabs';
import { getAllPackage, deletePackage } from '../../../service/admin/adminService'
import { useCookies } from '../../../hooks/useCookies'

function PackageManage() {
  const [typePackage, setTypePackage] = useState("all")
  const [hasDeletedPackage, setHasDeletedPackage] = useState(false)
  const [packages, setPackages] = useState({
    all: [],
    privat: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    },
    berkelompok: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    },
    regular: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    }
  })

  const { getCookie } = useCookies()
  const token = getCookie("token")

  useEffect(() => {
    const getPackages = async () => {
      const packages = await getAllPackage(token)

      if (packages.status === "success") {
        return setPackages({
          all: packages.data,
          privat: {
            all: packages.data.filter(pkg => pkg.type.type === "Privat"),
            online: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "aplikasi"),
            aplikasipendaftaran: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "aplikasi + pendaftaran"),
          },
          berkelompok: {
            all: packages.data.filter(pkg => pkg.type.type === "Berkelompok"),
            online: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "aplikasi"),
            aplikasipendaftaran: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "aplikasi + pendaftaran"),
          },
          regular: {
            all: packages.data.filter(pkg => pkg.type.type === "Regular"),
            online: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "aplikasi"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "aplikasi + pendaftaran"),
          },
        })
      }

      console.log(packages) // check error
    }

    setHasDeletedPackage(false)
    getPackages()

    return () => setPackages({})
  }, [token, hasDeletedPackage])

  return (
    <Fragment>
      <MainDashboard>

        <NavDashboard title="Kelola Paket" />
        <ContentDashboard>
          <div className='flex justify-between items-center'>
            <h1 className='text-xl font-semibold'>Pilih Paket</h1>
            <div className='flex items-center'>
              {/* <Link> */}
              {/* <div className='w-12 cursor-pointer'>
                <img src="/assets/icons/update.png" alt="update" />
              </div> */}
              {/* </Link> */}

              <Link to='/admin/package/add'>
                <div className='rounded-xl md:w-12 md:h-12 w-10 h-10 p-3 bg-blue cursor-pointer'>
                  <img src="/assets/icons/plus.png" className='w-full' alt="add" />
                </div>
              </Link>
            </div>
          </div>

          <TabPackage setTypePackage={setTypePackage} />

          <div className="mt-5">
            {typePackage === "all" && (
              <Fragment>
                <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.privat} type="Privat" />
                <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.berkelompok} type="BERKELOMPOK" />
                <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.regular} type="REGULAR" />
              </Fragment>
            )}

            {typePackage === "privat" && <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.privat} type="Privat" />}
            {typePackage === "berkelompok" && <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.berkelompok} type="BERKELOMPOK" />}
            {typePackage === "regular" && <Package setHasDeletedPackage={setHasDeletedPackage} packages={packages.regular} type="REGULAR" />}
          </div>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

function Package({ packages, type, setHasDeletedPackage }) {
  return (
    <Fragment>
      {packages?.online?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} ONLINE</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.online?.map((pkg) => {
              return <Card setHasDeletedPackage={setHasDeletedPackage} key={pkg.id} {...pkg} />
            })}
          </div>
        </div>
      )}

      {packages?.offline?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} OFFLINE</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.offline?.map(pkg => {
              return <Card setHasDeletedPackage={setHasDeletedPackage} key={pkg.id} {...pkg} />
            })}
          </div>
        </div>
      )}

      {packages?.aplikasi?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} APLIKASI</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.aplikasi?.map(pkg => {
              return <Card setHasDeletedPackage={setHasDeletedPackage} key={pkg.id} {...pkg} />
            })}
          </div>
        </div>
      )}

    {packages?.aplikasipendaftaran?.length !== 0 && (
            <div className='mb-6'>
              <span className='text-sm'>{type} APLIKASI + pendaftaran</span>
              <div className='grid md:grid-cols-3 gap-6 mt-6'>
                {packages?.aplikasipendaftaran?.map(pkg => {
                  return <Card setHasDeletedPackage={setHasDeletedPackage} key={pkg.id} {...pkg} />
                })}
              </div>
            </div>
          )}
    </Fragment>
  )
}

function Card({ classtype, meet, per, price, time, type, id, grades, setHasDeletedPackage }) {
  const filterGrades = grades.map(grade => grade.grade).sort((a, b) => a - b)

  const { getCookie } = useCookies()
  const token = getCookie("token")

  const handleClickDelete = async (idPackage) => {
    const confirmation = confirm("Yakin ingin menghapus paket ini?")
    if(confirmation) {
      const result = await deletePackage(idPackage, token)
      if(result.status === "Success") {
        setHasDeletedPackage(true)
      }
      return;
    }
  }

  return (
    <div className='flex flex-col shadow-gray p-6 rounded-xl md:items-center relative'>
      <span className='md:text-sm text-xs txt-blue'>{type.type} - Kelas {filterGrades.join("/")}</span>
      <div className='flex flex-col md:items-center md:mt-6 mt-3'>
        <span className='md:text-base text-sm'>{classtype}</span>
        <span className='block text-xl md:mt-2'><span className='text-2xl font-semibold'>Rp{(price).toLocaleString()}/</span>{per}</span>
      </div>
      <span className='text-xs mt-1'>{meet} pertemuan @ {time}</span>
      <div onClick={() => handleClickDelete(id)} className='md:mt-8 flex items-center md:relative md:right-0 md:top-0 absolute right-6 top-6'>
        <div className='bg-lightblue-3 w-8 h-8 ml-4 rounded-md flex items-center justify-center cursor-pointer'>
          <img className='transform scale-75' src="/assets/icons/trash-2.png" alt="delete" />
        </div>
      </div>
    </div>
  )
}

export default PackageManage