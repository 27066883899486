import { createContext, useContext, useState } from 'react';

const hiddenContext = createContext({
  hidden: false,
  setHidden: () => {}
})

function HiddenProvider({ children }) {
  const [hidden, setHidden] = useState(true)

  return (
    <hiddenContext.Provider value={{ hidden, setHidden }}>
      {children}
    </hiddenContext.Provider>
  )
}

export const useHidden = () => useContext(hiddenContext)

export default HiddenProvider