import { Fragment, useState, useRef } from 'react'
import { HeaderSignUp } from '../Header';
import { useForm } from "react-hook-form";
import { Input, InputGroup } from '../Input'
import TextRed from '../ErrorField';
import { REGEX } from '../../utils/regex'
import { CONSTANT } from '../../constant'
import { useSignUp } from '../../hooks/useSignup'
import showPassword from '../../utils/form/showPasswordInput'
import { registerService } from '../../service/register/registerService'
import { useMessageBackend } from '../../hooks/useMessageBackend'
import { useHistory } from 'react-router-dom';
import { loadImage } from '../../utils/common/loadImage'

export function FormCompleteParentProfile() {
    const [hasUploadImage, setHasUploadImage] = useState(false)
    const profileImageRef = useRef(), passwordRef = useRef()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [isEmailSame, setIsEmailSame] = useState(false)
    const { messageBackend, sendMessageFromBackend } = useMessageBackend()
    const history = useHistory()

    const { parent, student, typeForm, endStep, eventListener: { setState, setParent, setEndStep, setStepForm } } = useSignUp()


    const onSubmit = async (dataParent) => {
        if (parent.image === "") {
            return alert("Foto wajib diupload ")
        }

        if (endStep) {  // jika yang daftar sebagai ortu maka, bisa langsung kirim data ke backend
            delete parent.username


            await registerService(parent, "parent", sendMessageFromBackend, history, setStepForm)
            return setEndStep(true)
        }

        /* jika murid yang daftar akan di redirect ke form lengkapi profil murid, 
           setelah melengkapi profil ortunya */

        if (dataParent.email === student.email) { // jika emailnya sama maka tidak dapat lanjut ke step berikunya
            return setIsEmailSame(true)
        }


        delete parent.username
        setStepForm(3)
    }

    const onChange = (event) => {
        setParent({
            [event.target.name]: event.target.value
        })
    }

    const handleChangeImage = (image) => {
        loadImage(image, file => {
            profileImageRef.current.src = file.name

            setHasUploadImage(true)
            setState(prevValue => ({
                ...prevValue,
                parent: {
                    ...prevValue.parent,
                    image: file.name
                }
            }))
        })
    }

    return (
        <Fragment>
            <HeaderSignUp />
            <span className='md:mx-24 mx-6 md:my-8 block border-gray'></span>
            <div className='md:p-20 m-6 md:mb-24 mb-32 relative'>
                <div className='border-gray md:p-12 p-6 rounded-md'>
                    <form onChange={onChange} onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        <div className='flex items-center'>
                            <div className='md:w-24 w-20 mr-4 md:h-24 h-20 bg-gray-300 flex justify-center rounded-full overflow-hidden'>
                                <img ref={profileImageRef} className={`h-full w-full transform ${hasUploadImage ? 'scale-100' : 'scale-50'}`} src='/assets/icons/camera.png' alt="profile" />
                            </div>
                            <label className='bg-blue md:text-base text-sm p-3 text-white rounded-xl cursor-pointer' htmlFor='file'>Upload Foto</label>
                            <input name='image' register={register("image")} onChange={handleChangeImage} type="file" id='file' className='hidden' accept="image/png, image/jpeg" />
                        </div>

                        <div className='md:flex mt-8 justify-between'>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' htmlFor="username">Username*</label>
                                <Input register={register("username")} className='cursor-not-allowed' defaultValue={parent.email} readOnly={true} value={typeForm === 'parent' ? parent.email : ""} id="username" type="text" />
                                {errors.username && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                            <InputGroup className='md:pl-4 md:w-6/12 '>
                                <label className='mb-2 text-sm' htmlFor="email">Email*</label>
                                <Input register={register("email", {
                                    required: true,
                                })} defaultValue={parent.email} value={typeForm === 'parent' ? parent.email : ""} id="email" type="email" showIcon iconName='email' />
                                {(errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>)
                                    || (isEmailSame && <TextRed>{CONSTANT.IS_EMAIL_SAME}</TextRed>)
                                    || (messageBackend.email && <TextRed>{messageBackend.email.message}</TextRed>)
                                    || (messageBackend["parent.email"] && <TextRed>{messageBackend["parent.email"].message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div className=''>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' ref={passwordRef} htmlFor="password">Password*</label>
                                <Input register={register("password", {
                                    pattern: REGEX.password,
                                    required: true,
                                })} classIcon='right-6' defaultValue={parent.password} id="password" type="password" showIcon iconName='eye' cursorIcon='cursor-pointer' eventClickIcon={() => showPassword(passwordRef)} />
                                {errors.password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>}
                            </InputGroup>
                        </div>
                        <div className=''>
                            <InputGroup className='w-full'>
                                <label className='mb-2 text-sm' htmlFor="name">Nama*</label>
                                <Input register={register("name", {
                                    required: true,
                                })} showIcon iconName='name-people' defaultValue={parent.name} value={typeForm === 'parent' ? parent.name : ""} id="name" type="text" />
                                {(errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.name && <TextRed>{messageBackend.name.message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div className='md:flex justify-between'>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' htmlFor="phone">Nomor Telepon*</label>
                                <Input register={register("phone", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={parent.phone} id="phone" type="number" />
                                {(errors.phone && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.phone && <TextRed>{messageBackend.phone.message}</TextRed>)}
                            </InputGroup>
                            <InputGroup className='md:w-6/12 md:pl-4'>
                                <label className='mb-2 text-sm' htmlFor="telegram">Telegram*</label>
                                <Input register={register("telegram", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={parent.telegram} id="telegram" type="text" />
                                {errors.telegram && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                        </div>
                        <div className='md:flex justify-between'>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' htmlFor="whatsapp">Whatsapp*</label>
                                <Input register={register("whatsapp", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={parent.whatsapp} id="whatsapp" type="number" />
                                {(errors.whatsapp && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.whatsapp && <TextRed>{messageBackend.whatsapp.message}</TextRed>)}
                            </InputGroup>
                            <InputGroup className='md:w-6/12 md:pl-4'>
                                <label className='mb-2 text-sm' htmlFor="instagram">Instagram*</label>
                                <Input register={register("instagram", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={parent.instagram} id="instagram" type="text" />
                                {errors.instagram && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                        </div>
                        <div className=''>
                            <InputGroup className='w-full'>
                                <label className='mb-2 text-sm' htmlFor="informBy">Mendapatkan informasi Star Bimbel dari</label>
                                <Input register={register("informBy", {
                                    maxLength: 35,
                                })} defaultValue={parent.informBy} id="informBy" type="text" />
                            </InputGroup>
                        </div>
                        {endStep === true && (
                            <button type='submit' className='text-sm md:text-base absolute bg-blue text-white py-4 px-12 rounded-xl shadow-md md:bottom-0 -bottom-20 md:right-24 right-0'>Selesai</button>
                        )}

                        {endStep === false && (
                            <button type='submit' className='text-sm md:text-base absolute bg-blue text-white py-4 px-12 rounded-xl shadow-md md:bottom-0 -bottom-20 md:right-24 right-0'>Selanjutnya</button>
                        )}
                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export function FormCompleteStudentProfile() {
    const [hasUploadImage, setHasUploadImage] = useState(false)
    const profileImageRef = useRef(), dateOfBirthRef = useRef()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const { messageBackend, sendMessageFromBackend } = useMessageBackend()
    const history = useHistory()

    const { student, parent, typeForm, eventListener: { setState, setStudent, getAllState, setStepForm } } = useSignUp()

    const handleChangeImage = (image) => {
        loadImage(image, file => {
            profileImageRef.current.src = file.name

            setHasUploadImage(true)
            setState(prevValue => ({
                ...prevValue,
                student: {
                    ...prevValue.student,
                    image: file.name
                }
            }))
        })
    }

    const onSubmit = () => {
        if (parent.image === "" || student.image === "") {
            return alert("Foto wajib diupload")
        }

        registerService(getAllState, "student", sendMessageFromBackend, history, setStepForm)
    }

    const onChange = (event) => {
        setStudent({
            [event.target.name]: event.target.value
        })
    }

    console.log(messageBackend)

    return (
        <Fragment>
            <HeaderSignUp />
            <span className='md:mx-24 mx-6 md:my-8 block border-gray'></span>
            <div className='md:p-24 m-6 md:mb-32 mb-32 relative'>
                <div className='border-gray md:p-12 p-6 rounded-md'>
                    <form onChange={onChange} onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        <div className='flex items-center'>
                            <div className='md:w-24 w-20 mr-4 md:h-24 h-20 bg-gray-300 flex justify-center rounded-full overflow-hidden'>
                                <img ref={profileImageRef} className={`h-full w-full transform ${hasUploadImage ? 'scale-100' : 'scale-50'}`} src='/assets/icons/camera.png' alt="profile" />
                            </div>
                            <label className='bg-blue md:text-base text-sm p-3 text-white rounded-xl cursor-pointer' htmlFor='file'>Upload Foto</label>
                            <input name='image' register={register("image")} onChange={handleChangeImage} type="file" id='file' className='hidden' accept="image/png, image/jpeg" />
                        </div>
                        <div className=''>
                            <InputGroup className='w-full'>
                                <label className='mb-2 text-sm' htmlFor="fullName">Nama Lengkap*</label>
                                <Input register={register("fullName", {
                                    required: true,
                                })} defaultValue={student.fullName} id="fullName" type="text" />
                                {(errors.fullName && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend["student.fullName"] && <TextRed>{messageBackend["student.fullName"].message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div className=''>
                            <InputGroup className='w-full'>
                                <label className='mb-2 text-sm' htmlFor="name">Nama Panggilan*</label>
                                <Input register={register("name", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={typeForm === 'student' ? student.name : ""} value={typeForm === 'student' ? student.name : ""} id="name" type="text" />
                                {(errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend["student.name"] && <TextRed>{messageBackend["student.name"].message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div className='md:flex md:justify-between'>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' htmlFor="birthplace">Tempat Lahir*</label>
                                <Input register={register("birthplace", {
                                    required: true,
                                })} defaultValue={student.birthplace} id="birthplace" type="text" />
                                {errors.birthplace && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                            <InputGroup className='md:w-6/12 md:pl-4 w-full'>
                                <label className='mb-2 text-sm' ref={dateOfBirthRef} htmlFor="birthdate">Tanggal Lahir*</label>
                                <Input register={register("birthdate", {
                                    required: true,
                                })} defaultValue={student.birthdate} id="birthdate" type="date" className='w-full bg-white' />
                                {(errors.birthdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend["student.birthdate"] && <TextRed>{messageBackend["student.birthdate"].message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div className='md:flex justify-between'>
                            <InputGroup className='md:w-6/12 md:pr-4'>
                                <label className='mb-2 text-sm' htmlFor="phone">Nomor Telepon*</label>
                                <Input register={register("phone", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={student.phone} id="phone" type="number" />
                                {(errors.phone && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend["student.phone"] && <TextRed>{messageBackend["student.phone"].message}</TextRed>)}
                            </InputGroup>
                            <InputGroup className='md:w-6/12 md:pl-4'>
                                <label className='mb-2 text-sm' htmlFor="email">Email*</label>
                                <Input register={register("email", {
                                    required: true,
                                })} defaultValue={typeForm === 'student' ? student.email : ""} value={typeForm === 'student' ? student.email : ""} id="email" type="email" />
                                {(errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>) || (messageBackend["student.email"] && <TextRed>{messageBackend["student.email"].message}</TextRed>)}
                            </InputGroup>
                        </div>
                        <div>
                            <InputGroup className='w-full'>
                                <label className='mb-2 text-sm' htmlFor="school">Asal Sekolah*</label>
                                <Input register={register("school", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={student.school} id="school" type="text" />
                                {errors.school && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                        </div>
                        <div>
                            <InputGroup className='w-full'>
                                <label htmlFor="address">Alamat Lengkap*</label>
                                <textarea name='address' {...register("address", {
                                    maxLength: 35,
                                    required: true,
                                })} defaultValue={student.address} id="address" className='border-gray outline-none p-4 rounded-md h-36' />
                                {errors.address && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                            </InputGroup>
                        </div>
                        <div className='flex pb-12 md:pb-0 mt-6 w-full flex-col  justify-between absolute md:bottom-0 -bottom-38 right-0 md:px-24 '>
                            <div className='mb-4 mt-12 text-xs text-red-600'>
                                Jika setelah menekan tombol submit tidak terjadi apa2, silahkan cek kembali form sebelumnya atau form saat ini untuk melihat pesan error
                            </div>
                            <div className='flex justify-between'>
                                {messageBackend && (
                                    <button type='button' className='text-sm md:text-base bg-red-600 text-white py-4 px-12 rounded-xl shadow-md ' onClick={() => setStepForm(2)}>Kembali</button>
                                )}
                                <button type='submit' className='text-sm md:text-base bg-blue text-white py-4 px-12 rounded-xl shadow-md'>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}