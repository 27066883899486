import { Fragment, useState, useRef } from 'react'
import Container, { TextHaveAnAccount } from '../components/ContainerLogin'
import { Input, InputGroup } from '../components/Input'
import { useForm } from "react-hook-form";
import { useSignUp } from '../hooks/useSignup'
import TextRed from '../components/ErrorField'
import { REGEX } from '../utils/regex'
import { CONSTANT } from '../constant'
import { FormCompleteParentProfile, FormCompleteStudentProfile } from '../components/CompleteForm'
import showPassword from '../utils/form/showPasswordInput'

function StepSignup() {
    const { stepForm } = useSignUp()
    return (
        <Fragment>
            {stepForm === 1 && (
                <Container title="Selamat Datang di Star Bimbel" subTitle="Mari Belajar dengan Bahagian dan Tingkatkan Prestasi Bersama Star Bimbel">
                    <FormContainer />
                </Container>
            )}
            {stepForm === 2 && <FormCompleteParentProfile />}
            {stepForm === 3 && <FormCompleteStudentProfile />}
        </Fragment >

    )
}

function FormContainer() {
    const [activeTab, setActiveTab] = useState('parent');

    const changeActiveTab = (type) => {
        setActiveTab(type)
    }

    return (
        <Fragment>
            <h1 className='text-2xl font-semibold'>Daftar Sebagai</h1>
            <TextHaveAnAccount />
            <div className='flex w-full mt-4'>
                <button className={`${activeTab === 'student' ? 'bg-blue text-white z-10' : 'bg-gray text-black'} py-4 md:px-12 md:text-base text-sm rounded-xl md:w-52 w-32`} onClick={() => changeActiveTab('student')}>Murid</button>
                <button className={`${activeTab === 'parent' ? 'bg-blue text-white z-10' : 'bg-gray text-black'} relative md:-left-8 -left-4 md:text-base text-sm py-4 md:px-12 rounded-xl md:w-52 w-32`} onClick={() => changeActiveTab('parent')}>Orang Tua</button>
            </div>
            <span className='mt-8 mx-auto w-2/5 h-0.5 border-gray'></span>
            <TypeForm activeTab={activeTab} />
        </Fragment>
    )
}

function TypeForm({ activeTab }) {
    if (activeTab === 'parent') {
        return <FormSignup type={activeTab} />
    }

    return <FormSignup type={activeTab} />
}

function FormSignup({ type }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { eventListener: { setStepForm, setEndStep, setParent, setStudent, setTypeForm } } = useSignUp()
    const passwordRef = useRef()

    const onSubmit = (data) => {
        if (type === 'parent') {
            setParent(data)

            setEndStep(true)
            return setStepForm(2)
        }

        setTypeForm('student')
        setEndStep(false)
        setStudent(data)
        setStepForm(2)
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                    <label className='mb-2 text-sm' htmlFor="name">Nama</label>
                    <Input register={register(`name`, {
                        maxLength: 35,
                        required: true,
                    })} id="name" type="text" placeholder="Nama Lengkap" showIcon iconName='name-people' />
                     {errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>

                <InputGroup>
                    <label className='mb-2 text-sm' htmlFor="email">Username</label>
                    <Input register={register(`email`, {
                        pattern: REGEX.email,
                        maxLength: 35,
                        required: true,
                    })} id="email" type="email" placeholder="Alamat Email" showIcon iconName='email' />
                    {errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>}
                </InputGroup>

                <InputGroup>
                    <label ref={passwordRef} className='mb-2 text-sm' htmlFor="password">Password</label>
                    <Input register={register("password", {
                        pattern: REGEX.password,
                        required: true,
                    })} id="password" type="password" placeholder="Masukan Password" showIcon iconName='eye' eventClickIcon={() => showPassword(passwordRef)} cursorIcon='cursor-pointer' />
                    {errors.password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>}
                </InputGroup>

                <button className='shadow-md w-full p-4 rounded-2xl text-white bg-blue mt-6 md:text-base text-sm'>Daftar</button>
                {/* <AnotherSignup /> */}
            </form>
        </Fragment>
    )
}

// eslint-disable-next-line
function AnotherSignup() {
    return (
        <div className='flex flex-col mt-6'>
            <div className='flex items-center txt-gray justify-evenly'>
                <span className='h-0.5 w-2/6 border-gray'></span>
                <span className='font-semibold md:text-base text-sm'>OR</span>
                <span className='h-0.5 w-2/6 border-gray'></span>
            </div>

            <div className='flex mt-6 justify-center items-center'>
                <button className='w-1/2 flex justify-center border-black mr-4 rounded-xl text-center p-3 md:text-sm text-xs items-center'>
                    <img className='w-6 mr-2' src="/assets/icons/google.png" alt="google" />
                Google
              </button>
                <button className='w-1/2 flex justify-center border-black rounded-xl text-center p-3 md:text-sm text-xs items-center'>
                    <img className='w-6 mr-2' src="/assets/icons/fb.png" alt="facebook" />
              Facebook
            </button>
            </div>
        </div>
    )
}

export default StepSignup