import { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

function Tabs({ lesson, arrow = false, bgLink = 'bg-white', teacher, grade }) {
  // const [showDetail, setShowDetail] = useState(false)
  const history = useHistory()
  const [activeModul, setActiveModul] = useState(null)

  // const handleClickArrow = () => {
  //   if (showDetail) {
  //     return setShowDetail(false)
  //   }
  //   setShowDetail(true)
  // }

  const handleClickCategoryModul = (id) => {
    setActiveModul(id)
    if(teacher) {
      return history.push(`/teacher/modul?id=${id}&class=${grade}`)
    }

    history.push(`/dashboard/modul?id=${id}`)
  }

  console.log(lesson)

  return (
    <Fragment>
      <div className='flex w-full lg:w-max lg:flex-row flex-col mt-8'>
        <h1 className='lg:hidden text-xl mb-3 font-medium'>Modul</h1>
        <ul className='flex lg:flex-row flex-col lg:bg-transparent bg-white w-full lg:p-0 p-6 lg:rounded-none rounded-xl'>
          {lesson?.map(lesson => {
            console.log(lesson)
            return (
              <li onClick={() => handleClickCategoryModul(lesson.idModul)} key={lesson.idModul} className={`flex ${lesson.idModul === activeModul && 'border border-blue'} items-center lg:p-3 p-5 ${bgLink} lg:shadow-none shadow-gray rounded-xl cursor-pointer lg:mr-6 mr-0 lg:mb-0 mb-4`}>
                <div className='w-8 '>
                  <img className='' src={`${lesson.icon}`} alt={`icon`} />
                </div>
                <span className='block ml-2 lg:text-sm text-xs'>{lesson.nameModul}</span> 
              </li>
            )
          })}
        </ul>
        {/* {arrow && (
          <div className='lg:flex hidden w-12 items-center p-3 bg-white rounded-xl cursor-pointer mr-6' onClick={handleClickArrow}>
            <img src="/assets/icons/arrow.png" alt="ppkn" />
          </div>
        )} */}
      </div>
      {/* <DetailTabs teacher={teacher} grade={grade} show={showDetail} lesson={lesson} /> */}
    </Fragment>
  )
}

// function DetailTabs({ show, lesson, teacher, grade }) {
//   if (show) {
//     return (
//       <div className='lg:absolute relative left-0 right-0 lg:top-16 top-2 z-10 shadow-xl rounded-l-2xl rounded-br-2xl lg:w-full w-max overflow-x-auto'>
//         <div className='bg-white lg:w-max px-6 pb-8 pt-1 shadow-xl rounded-l-2xl rounded-br-2xl overflow-x-scroll'>
//           <Tabs teacher={teacher} grade={grade} lesson={lesson} bgLink='bg-gray' />
//         </div>
//       </div>
//     )
//   }

//   return <></>
// }

export function TabPackage({ setTypePackage }) {
  const [activeTab, setActiveTab] = useState('all')

  const handleClikTabPackage = (button) => {
    const dataTab = button.target.dataset.tab;
    setTypePackage(dataTab)
    setActiveTab(dataTab)
  }

  return (
    <div className='mt-6 flex w-full overflow-x-scroll'>
      <button onClick={handleClikTabPackage} data-tab="all" className={`w-max ${activeTab === "all" ? 'bg-blue text-white' : 'bg-gray2 '} md:text-base text-xs rounded-md py-1 md:px-6 px-2`}>All</button>
      <button onClick={handleClikTabPackage} data-tab="privat" className={`w-max ${activeTab === "privat" ? 'bg-blue text-white' : 'bg-gray2 '} md:text-base text-xs ml-4 rounded-md py-1 md:px-6 px-2`}>Privat</button>
      <button onClick={handleClikTabPackage} data-tab="berkelompok" className={`w-max ${activeTab === "berkelompok" ? 'bg-blue text-white' : 'bg-gray2 '} md:text-base text-xs ml-4 rounded-md py-1 md:px-6 px-2`}>BERKELOMPOK</button>
      <button onClick={handleClikTabPackage} data-tab="regular" className={`w-max ${activeTab === "regular" ? 'bg-blue text-white' : 'bg-gray2 '} md:text-base text-xs ml-4 rounded-md py-1 md:px-6 px-2`}>REGULAR</button>
    </div>
  )
}

export default Tabs