import { Fragment, useEffect, useState } from 'react'
import { ContentDashboard, MainDashboard } from '../../../layouts/Dashboard'
import { NavDashboard } from '../../../components/Navbar'
import { PopupPaymentProcess } from '../../../components/Popup'
import { Table, TableHead, TableBody, TableRow, TableData } from '../../../components/Table'
import { useCookies } from '../../../hooks/useCookies'
import { getAllPayment, paymentVerify } from '../../../service/admin/adminService'

function PaymentManage() {
  const [showPopup, setShowPopup] = useState(false)
  const [allPayments, setAllPayments] = useState([])
  const { getCookie } = useCookies()
  const [hasVerifiedPayment, setHasVerifiedPayment] = useState(false)

  const [filteredPayment, setFilteredPayment] = useState()


  const token = getCookie("token")

  useEffect(() => {
    const fecthPayment = async () => {
      const result = await getAllPayment(token)
      console.log(result)
      setAllPayments(result.data)
      setHasVerifiedPayment(false)
    }

    fecthPayment()
  }, [token, hasVerifiedPayment])

  const handleClickStatus = (idPayment) => {
    setFilteredPayment(allPayments.filter(payment => payment.idPayment === idPayment))
    setShowPopup(true)
  }

  const handleClickVerify = async (status) => {
    const result = await paymentVerify({
      idPayment: filteredPayment[0].idPayment,
      status: status
    }, token)

    if(result.status === "Success") {
      alert(`Berhasil Melakukan Update Pembayaran dengan status ${status}`)
      setShowPopup(false)
      return setHasVerifiedPayment(true)
    }
  }

  return (
    <Fragment>
      <PopupPaymentProcess onClickReject={() => handleClickVerify("ditolak")} onClickVerifiy={() => handleClickVerify("diterima")} open={showPopup} close={setShowPopup} payment={filteredPayment}/>
      <MainDashboard>
        <NavDashboard title="Kelola Pembayaran"  />
        <ContentDashboard>
          <Table>
            <TableHead className='md:flex hidden'>
              <TableRow>
                <TableData>ID Orang Tua</TableData>
                <TableData>ID Murid</TableData>
                <TableData>Nominal</TableData>
                <TableData>Foto Bukti</TableData>
                <TableData>Paket</TableData>
                <TableData>Status</TableData>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                allPayments?.map((user, id) => {
                  if(user.enddate != null)
                  {
                  return (
                    <TableRow className='md:flex-row flex-col relative' key={id}>
                      <TableData className='flex md:order-1 md:mt-0 mt-3 order-2 flex-col justify-between md:h-32'>
                        <div className='flex flex-col'>
                          <span>{user.idParent}</span>
                          <span className='txt-gray'>{user.parentName}</span>
                        </div>
                        <div>
                          <span className='text-xs txt-gray block w-full'>{user.startdate.split("T")[0]} s/d</span>
                          <span className='text-xs txt-gray block w-full'>{user.enddate.split("T")[0]}</span>
                        </div>
                      </TableData>
                      <TableData className='flex md:order-2 md:mt-0 order-1 flex-col justify-between md:h-32'>
                        <div className='flex flex-col md:mt-0 mt-3'>
                          <span>{user.idStudent}</span>
                          <span className='txt-gray'>{user.sudentName}</span>
                        </div>
                      </TableData>
                      <TableData className='md:mt-0 md:order-3 order-4 mt-3 md:text-base text-lg md:font-normal font-medium'>Rp{user.total_price.toLocaleString("id-ID")}</TableData>
                      <TableData className='md:static md:order-4 order-6 absolute right-0'>
                        <div className='flex flex-col md:h-full h-28'>
                          {/* <span className='text-red-500 md:text-base text-sm'>FotoBukti.jpg</span> */}
                          <div className=''>
                            <a href={`https://api.starbimbel.com/foto/${user?.image}`} target="blank">
                              <img src={`https://api.starbimbel.com/foto/${user?.image}`} className='h-24 object-contain' alt="bukti" />
                            </a>
                          </div>
                        </div>
                      </TableData>
                      <TableData className='md:mt-0 md:order-5 order-3 mt-3'>{user.PackageType}</TableData>
                      <TableData className='md:static md:order-6 absolute md:bottom-0 bottom-4 right-0'>
                        <button onClick={() => handleClickStatus(user.idPayment)} disabled={(user.status !== "proses" && user.status !== "pending")} className={`${(user.status === "proses" || user.status === "pending") ? "bg-yellow-400 text-white" : user.status === "diterima" ? "border-green-200 text-green-200 border-2 cursor-not-allowed" : "border-red-400 text-red-400 border-2 cursor-not-allowed"} py-2 px-6 rounded-xl md:text-base text-xs`} >
                          {user?.status}
                        </button>
                      </TableData>
                    </TableRow>
                  )
                  }else{
                    return (
                      <TableRow className='md:flex-row flex-col relative' key={id}>
                        <TableData className='flex md:order-1 md:mt-0 mt-3 order-2 flex-col justify-between md:h-32'>
                          <div className='flex flex-col'>
                            <span>{user.idParent}</span>
                            <span className='txt-gray'>{user.parentName}</span>
                          </div>
                          <div>
                            <span className='text-xs txt-gray block w-full'>{user.startdate.split("T")[0]} s/d</span>
                            <span className='text-xs txt-gray block w-full'>null</span>
                          </div>
                        </TableData>
                        <TableData className='flex md:order-2 md:mt-0 order-1 flex-col justify-between md:h-32'>
                          <div className='flex flex-col md:mt-0 mt-3'>
                            <span>{user.idStudent}</span>
                            <span className='txt-gray'>{user.sudentName}</span>
                          </div>
                        </TableData>
                        <TableData className='md:mt-0 md:order-3 order-4 mt-3 md:text-base text-lg md:font-normal font-medium'>Rp{user.total_price.toLocaleString("id-ID")}</TableData>
                        <TableData className='md:static md:order-4 order-6 absolute right-0'>
                          <div className='flex flex-col md:h-full h-28'>
                            {/* <span className='text-red-500 md:text-base text-sm'>FotoBukti.jpg</span> */}
                            <div className=''>
                              <a href={`https://api.starbimbel.com/foto/${user?.image}`} target="blank">
                                <img src={`https://api.starbimbel.com/foto/${user?.image}`} className='h-24 object-contain' alt="bukti" />
                              </a>
                            </div>
                          </div>
                        </TableData>
                        <TableData className='md:mt-0 md:order-5 order-3 mt-3'>{user.PackageType}</TableData>
                        <TableData className='md:static md:order-6 absolute md:bottom-0 bottom-4 right-0'>
                          <button onClick={() => handleClickStatus(user.idPayment)} disabled={(user.status !== "proses" && user.status !== "pending")} className={`${(user.status === "proses" || user.status === "pending") ? "bg-yellow-400 text-white" : user.status === "diterima" ? "border-green-200 text-green-200 border-2 cursor-not-allowed" : "border-red-400 text-red-400 border-2 cursor-not-allowed"} py-2 px-6 rounded-xl md:text-base text-xs`} >
                            {user?.status}
                          </button>
                        </TableData>
                      </TableRow>
                    )
                  }
                }
                )
                
              }
            </TableBody>
          </Table>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default PaymentManage