import { createContext, useContext, useState } from 'react';

const toggleContext = createContext({
  minimized: false,
  setMinimized: () => {},
  setHiddenSidebar: () => {}
})

function ToggleProvider({ children }) {
  const [minimized, setMinimized] = useState(false)
  const [hiddenSidebar, setHiddenSidebar] = useState(false)

  return (
    <toggleContext.Provider value={{ minimized, setMinimized, hiddenSidebar, setHiddenSidebar }}>
      {children}
    </toggleContext.Provider>
  )
}

export const useToggle = () => useContext(toggleContext)

export default ToggleProvider