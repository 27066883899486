import { Fragment, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { loadImage } from '../../../utils/common/loadImage';
import { useForm } from "react-hook-form";
import { updateStudent, changePassword, passwordCheck, } from '../../../service/students/studentService'
import { updateStudentByParent } from '../../../service/parent/parentService'
import { TitleForm } from '../../Form'
import { Input, InputGroup } from '../../Input'
import { CONSTANT } from '../../../constant'
import TextRed from '../../ErrorField'
import { disableKeySpace } from '../../../utils/form/disableKeySpace'
import { REGEX } from '../../../utils/regex'
import { useQuery } from '../../../hooks/useQuery'
import { useCookies } from '../../../hooks/useCookies';

export function EditStudentForm({ changeEditTypeTo, profile, token, admin = false }) {
  const profileImageRef = useRef(), fileImageRef = useRef()
  const [hasUploadImage, setHasUploadImage] = useState(false)
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  const [image, setImage] = useState('')
  const history = useHistory()
  const id = useQuery("id")

  const handleChangeImage = (image) => {
    if (image.target.files.length === 0) {
      return
    }

    loadImage(image, file => {
      profileImageRef.current.src = file.name
      setImage(file.name)
      setHasUploadImage(true)
    })
  }

  const onSubmit = async (data) => {
    data.image = image
    if (image === "") {
      return alert("Foto wajib diisi")
    }

    if (!admin) {
      const result = await updateStudent(data, token)
      if (result.status === "success") {
        return history.push('/dashboard/modul')
      }
      console.log(result)
    }

    const result = await updateStudentByParent(data, token, id)
    if (result.status === "success") {
      return history.push('/parent/students')
    }
    console.log(result)
  }

  return (
    <Fragment>
      <TitleForm iconClose hrefClose='/parent/students' linkEditPassword hrefLinkEditPassword={changeEditTypeTo}>Edit Profil</TitleForm>
      <form className='mt-6 md:px-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex items-center mb-6'>
          <div className='md:w-24 w-20 mr-4 md:h-24 h-20 bg-gray-300 flex justify-center rounded-full overflow-hidden'>
            <img ref={profileImageRef} className={`h-full w-full transform ${hasUploadImage ? 'scale-100' : 'scale-50'}`} src='/assets/icons/camera.png' alt="profile" />
          </div>
          <label ref={fileImageRef} className='bg-blue md:text-base text-xs p-3 text-white rounded-xl cursor-pointer' htmlFor='file'>Upload Foto Baru</label>
          <input {...register("image")} onChange={handleChangeImage} type="file" id='file' className='hidden' accept="image/png, image/jpeg" />
        </div>

        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="fullName">Nama Lengkap*</label>
          <Input defaultValue={setValue("fullName", profile?.fullName) ?? ""} register={register("fullName", { required: true })} id="fullName" type="text" placeholder="Nama Lengkap" showIcon iconName="name-people" />
          {errors.fullName && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="name">Nama Panggilan*</label>
          <Input defaultValue={setValue("name", profile?.name) ?? ""} onKeyDown={disableKeySpace} register={register("name", { required: true })} id="name" type="text" placeholder="Nama Panggilan" />
          {errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>


        <div className='md:flex justify-between'>
          <InputGroup className='md:w-6/12 md:pr-4'>
            <label className='mb-2 text-sm' htmlFor="birthplace">Tempat Lahir*</label>
            <Input defaultValue={setValue("birthplace", profile?.birthplace) ?? ""} register={register("birthplace", { required: true })} id="birthplace" placeholder="Masukan Email" />
            {errors.birthplace && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup className='md:pl-4 md:w-6/12 '>
            <label className='mb-2 text-sm' htmlFor="birthdate">Tanggal Lahir*</label>
            <Input defaultValue={setValue("birthdate", profile?.birthdate?.split('T')[0]) ?? ""} register={register("birthdate", { required: true })} id="birthdate" type='date' className='w-full' />
            {errors.birthdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>

        <div className='md:flex justify-between'>
          <InputGroup className='md:w-6/12 md:pr-4'>
            <label className='mb-2 text-sm' htmlFor="email">Email*</label>
            <Input defaultValue={setValue("email", profile?.email) ?? ""} register={register("email", { required: true })} id="email" placeholder="Masukan Email" showIcon iconName='email' classIcon='right-7' />
            {errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>}
          </InputGroup>
          <InputGroup className='md:w-6/12 md:pl-4'>
            <label className='mb-2 text-sm' htmlFor="phone">Nomor Telepon*</label>
            <Input defaultValue={setValue("phone", profile?.phone) ?? ""} register={register("phone", { required: true })} id="phone" type="text" />
            {errors.phone && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>

        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="school">Asal Sekolah*</label>
          <Input defaultValue={setValue("school", profile?.school) ?? ""} register={register("school", { required: true })} id="school" type="text" />
          {errors.school && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="address">Alamat Lengkap*</label>
          <textarea defaultValue={setValue("address", profile?.address) ?? ""} {...register("address", { required: true })} className='p-4 border-gray outline-none rounded-md' />
          {errors.address && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <div className='w-full flex justify-end'>
          <button className='mt-8 bg-blue py-4 px-8 text-white rounded-xl md:text-sm text-xs'>Simpan</button>
        </div>
      </form>
    </Fragment>
  )
}

export function EditPasswordStudentForm() {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [messageBackend, setMessageBackend] = useState({
    oldPassword: "",
    repeatPassword: ""
  })
  const history = useHistory()
  const newPasswordRef = useRef(), repeatPasswordRef = useRef()

  const onSubmit = async (data) => {
    const repeatPassword = repeatPasswordRef.current.nextElementSibling.value

    if (data.new_password === repeatPassword) {
      setMessageBackend({ oldPassword: false, repeatPassword: false })
      const result = await changePassword({ password: data.new_password }, token)

      if (result.status === "success") {
        alert(result.message)
        return history.push('/parent/students')
      }
    }

    return setMessageBackend({ oldPassword: false, repeatPassword: "Pasword tidak sesuai" })
  }

  const handleChangeOldPassword = async (event) => {
    const oldPassword = await passwordCheck({ password: event.target.value }, token)
    if (oldPassword.message === "Password Sesuai") {
      return setMessageBackend(prev => ({ ...prev, oldPassword: false }))
    }

    setMessageBackend(prev => ({ ...prev, oldPassword: oldPassword.message }))
  }

  const handleChangeNewPassword = (input) => {
    const newPassword = newPasswordRef.current.nextElementSibling.value
    const repeatPassword = input.target.value

    if (repeatPassword !== newPassword) {
      return setMessageBackend(prev => ({ ...prev, repeatPassword: "Password tidak sesuai dengan password baru" }))
    }

    setMessageBackend(prev => ({ ...prev, repeatPassword: false }))
  }

  return (
    <Fragment>
      <TitleForm>Edit Password</TitleForm>
      <form className='mt-6 px-4' onSubmit={handleSubmit(onSubmit)}>
        <InputGroup className=''>
          <label className='mb-2 text-sm' htmlFor="old_password">Password Lama*</label>
          <Input onChange={handleChangeOldPassword} required id="old_password" type="password" placeholder="Masukan Password Lama" />
          {messageBackend.oldPassword && <TextRed>{messageBackend.oldPassword}</TextRed>}
        </InputGroup>

        <InputGroup className=''>
          <label ref={newPasswordRef} className='mb-2 text-sm' htmlFor="new_password">Password Baru*</label>
          <Input register={register("new_password", { required: true, pattern: REGEX.password })} id="new_password" type="password" placeholder="Masukan Password Baru" />
          {errors.new_password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>}
        </InputGroup>

        <InputGroup className=''>
          <label ref={repeatPasswordRef} className='mb-2 text-sm' htmlFor="repeat_password">Ulangi Password Baru*</label>
          <Input onChange={handleChangeNewPassword} required id="repeat_password" type="password" placeholder="Masukan Password Baru" />
          {messageBackend.repeatPassword && <TextRed>{messageBackend.repeatPassword}</TextRed>}
        </InputGroup>

        <div className='w-full flex justify-end'>
          <button className='mt-8 bg-blue py-4 px-8 text-white rounded-xl'>Ubah</button>
        </div>
      </form>
    </Fragment>
  )
}