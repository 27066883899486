import { Fragment, useEffect, useState } from 'react'
import { CardPackage } from '../../../components/Cards'
import { usePaymentData } from '../../../hooks/usePaymentData'
import { useHistory, useParams } from 'react-router-dom'
import TextRed from '../../../components/ErrorField'
import { NavDashboard } from '../../../components/Navbar'
import { ContentDashboard } from '../../../layouts/Dashboard'
import { TabPackage } from '../../../components/Tabs'
import { getAllPackage } from '../../../service/parent/parentService'
import { useCookies } from '../../../hooks/useCookies'

function PackageStudent() {
  return (
    <Fragment>
      <NavDashboard title="Murid" />
      <ContentDashboard>
        <ChoosePackage />
      </ContentDashboard>
    </Fragment>
  )
}

function ChoosePackage() {
  const [notChoosePackage, setNotChoosePackage] = useState(false)
  const { eventListener: { addStudentID, setStepPayment }, state: { idPackage } } = usePaymentData()
  const { id } = useParams()
  const history = useHistory()
  const { getCookie } = useCookies()

  const [typePackage, setTypePackage] = useState("all")
  const [packages, setPackages] = useState({
    all: [],
    privat: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    },
    berkelompok: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    },
    regular: {
      all: [],
      online: [],
      offline: [],
      aplikasi: [],
      aplikasipendaftaran: [],
    }
  })

  const token = getCookie('token')

  useEffect(() => {
    const getPackages = async () => {
      const packages = await getAllPackage(token)
      if (packages.status === "success") {
        return setPackages({
          all: packages.data,
          privat: {
            all: packages.data.filter(pkg => pkg.type.type === "Privat"),
            online: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "aplikasi"),
            aplikasipendaftaran: packages.data.filter(pkg => pkg.type.type === "Privat" && pkg.classtype === "aplikasi + pendaftaran"),
          },
          berkelompok: {
            all: packages.data.filter(pkg => pkg.type.type === "Berkelompok"),
            online: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "aplikasi"),
            aplikasipendaftaran: packages.data.filter(pkg => pkg.type.type === "Berkelompok" && pkg.classtype === "aplikasi + pendaftaran"),
          },
          regular: {
            all: packages.data.filter(pkg => pkg.type.type === "Regular"),
            online: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "online"),
            offline: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "offline"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "aplikasi"),
            aplikasi: packages.data.filter(pkg => pkg.type.type === "Regular" && pkg.classtype === "aplikasi + pendaftaran"),
          },
        })
      }

      console.log(packages) // check error
    }

    getPackages()

    return () => setPackages({})
  }, [token])

  const handleClickBayar = () => {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm("Yakin ingin bayar Package ini?")

    if (idPackage.length === 0) {
      return setNotChoosePackage(true)
    }

    if (prompt) {
      addStudentID(id)
      history.push('/parent/payment')
      return setStepPayment(2)
    }
  }

  return (
    <Fragment>
      <div className='flex justify-between items-center'>
        <h1 className='text-xl font-semibold'>Pilih Paket</h1>
        <button className='rounded-xl border-blue md:text-md text-sm txt-blue py-2 px-6 cursor-pointer' onClick={handleClickBayar}>Bayar</button>
      </div>
      {notChoosePackage && <TextRed>Pastikan Memilih Paket terlebih dahulu, sebelum bayar</TextRed>}

      <TabPackage setTypePackage={setTypePackage} />

      <div className="mt-5">
        {typePackage === "all" && (
          <Fragment>
            <Package setStepPayment={setStepPayment} packages={packages.privat} type="Privat" />
            <Package setStepPayment={setStepPayment} packages={packages.berkelompok} type="BERKELOMPOK" />
            <Package setStepPayment={setStepPayment} packages={packages.regular} type="REGULAR" />
          </Fragment>
        )}

        {typePackage === "privat" && <Package setStepPayment={setStepPayment} packages={packages.privat} type="Privat" />}
        {typePackage === "berkelompok" && <Package setStepPayment={setStepPayment} packages={packages.berkelompok} type="BERKELOMPOK" />}
        {typePackage === "regular" && <Package setStepPayment={setStepPayment} packages={packages.regular} type="REGULAR" />}
      </div>
    </Fragment>
  )
}

function Package({ packages, type, setStepPayment }) {
  return (
    <Fragment>
      {packages?.online?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} ONLINE</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.online?.map((pkg) => {
              return <CardPackage key={pkg.id} setStep={setStepPayment} {...pkg} />
            })}
          </div>
        </div>
      )}

      {packages?.offline?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} OFFLINE</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.offline?.map(pkg => {
              return <CardPackage key={pkg.id} {...pkg} setStep={setStepPayment} />
            })}
          </div>
        </div>
      )}

      {packages?.aplikasi?.length !== 0 && (
        <div className='mb-6'>
          <span className='text-sm'>{type} APLIKASI</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {packages?.aplikasi?.map(pkg => {
              return <CardPackage key={pkg.id} {...pkg} setStep={setStepPayment} />
            })}
          </div>
        </div>
      )}

      {packages?.aplikasipendaftaran?.length !== 0 && (
            <div className='mb-6'>
              <span className='text-sm'>{type} APLIKASI + pendaftaran</span>
              <div className='grid md:grid-cols-3 gap-6 mt-6'>
                {packages?.aplikasipendaftaran?.map(pkg => {
                  return <CardPackage key={pkg.id} {...pkg} setStep={setStepPayment} />
                })}
              </div>
            </div>
          )}
      
    </Fragment>
  )
}

export default PackageStudent